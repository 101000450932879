.page {
  margin-left: auto;
  margin-right: auto;
  width: 1024px;
  min-height: calc(100vh - 140px);
}

.fullScreen {
  height: 100vh;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  background: url("./assets/background.svg");
}

.wide {
  margin-left: auto;
  margin-right: auto;
  width: 90%;
  max-width: 1920px;
  min-width: 1024px;
  min-height: calc(100vh - 140px);
}

.flexible {
  margin-left: auto;
  margin-right: auto;
  width: 90%;
  max-width: 1920px;
  min-width: 1024px;
  display: flex;
  flex-direction: column;
  height: calc(100vh - 140px);
}
