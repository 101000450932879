
/* используем цвета мегафона */

/* основной цвет фреймворка завязан на blue */
$blue1: #44024f !default;
$blue2: #5c0c6a !default;
$blue3: #721982 !default;
$blue4: #833292 !default;
$blue5: #9a52a7 !default;

$green1: #027e11 !default;
$green2: #0da01f !default;
$green3: #28b93a !default;
$green4: #47c857 !default;
$green5: #71da7e !default;

/* доп цвета мегафона */
$orange1: #a56600 !default;
$orange2: #d28200 !default;
$orange3: #ffa617 !default;
$orange4: #ffb845 !default;
$orange5: #ffc86f !default;

$red1: #a52109 !default;
$red2: #d23518 !default;
$red3: #eb5b40 !default;
$red4: #ff7f67 !default;
$red5: #ffa291 !default;

$light-blue1: #15b2c0;
$light-blue2: #37c8d6;
$light-blue3: #5bd9e5;
$light-blue4: #86e7f0;
$light-blue5: #bdf5fa;

$dark-blue1: #151253;
$dark-blue2: #2c2971;
$dark-blue3: #444189;
$dark-blue4: #6765a5;
$dark-blue5: #9594c5;

@import "~@blueprintjs/core/src/common/_colors.scss";

$cl-primary: $blue3;
$cl-success: $green3;
$cl-warning: $orange3;
$cl-danger: $red3;
$cl-border: rgba($black, 0.15);
