@import "../../../../styles/colors.scss";

.treeCard {
  width: 442px;
  margin-left: 10px;
  display: flex;
  flex-direction: column;

  .searchWrapper {
    display: flex;
    flex-direction: row;
    margin-bottom: 10px;
  }

  .treeWrapper {
    border: 1px solid rgba($gray1, 0.4);
    overflow: auto;
    flex: 1 1 100px;

    .treeSpinner {
      margin-top: 20px;
    }

    .nodeSpinner {
      margin-right: 3px;
    }

    .notFound {
      padding: 10px;
      color: rgba($gray1, 0.4);
    }

    :global(.bp3-tree-node-label) {
      display: flex;
      flex-direction: row;
      align-items: center;

      .color {
        width: 10px;
        min-width: 10px;
        height: 10px;
        border-radius: 5px;
        border: 1px solid $gray1;
        margin-right: 5px;
      }

      .labelText {
        flex: 1 1 auto;
        line-height: 22px;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .priorityBlock {
        width: 18px;
        height: 18px;
        margin: 0 10px;

        .priority {
          width: 18px;
          height: 18px;
          border-radius: 9px;
          background-color: $gray1;
          color: #fff;
          font-weight: 500;
          text-align: center;
          line-height: 18px;
        }

        :global(.bp3-popover-target) {
          display: inline-block !important;
        }
      }

      .noPriority {
        width: 18px;
        height: 18px;
        margin: 0 10px;
      }

      .optIconBlock {
        margin: 0 10px;
        font-size: large;

        .optIcon {
          margin-top: 2px;
        }

        :global(.bp3-popover-target) {
          display: inline-block !important;
        }
      }

      .optIconBlockEmpty {
        margin: 0 10px;
        opacity: 0.1;
        font-size: large;
      }

      .optIconModifierWrapper {
        margin-top: 2px;
        display: inline-flex;

        .optIconModifier {
          width: 0;
          transform: translate(-16px, 0);
        }
      }
    }
  }
}
