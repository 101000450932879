@import "../../../../styles/colors.scss";

.equipmentWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 10px 10px 10px 60px;
  flex: 0 0 auto;

  .priority {
    font-weight: 500;
    margin-bottom: 10px;
  }
}

.equipment {
  border: 1px solid rgba($gray1, 0.4);
  background: mix($gray1, #fff, $weight: 15%);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-radius: 6px;
  padding: 2px;
  min-width: 120px;

  &.over {
    padding: 0;
    border-radius: 0;
    border: 3px dashed rgba($gray1, 0.6);
  }

  .clearButton {
    height: 14px;
    width: 14px;
    min-width: 14px;
    min-height: 14px;
    padding: 2px;
    border: none !important;
    box-shadow: none !important;

    :global(svg) {
      width: 10px;
      height: 10px;
    }
  }

  .equipmentTitle {
    font-weight: 500;
    text-align: center;
    min-height: 14px;
    display: flex;
    flex-direction: row;

    .titleWrapper {
      flex: 1 1 auto;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      padding: 0 5px;
    }
  }

  .equipmentBody {
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
  }

  .shelf {
    border: 1px solid rgba($gray1, 0.4);
    background: #fff;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: stretch;
    border-radius: 6px;
    margin: 2px;
    flex: 1 1 40px;

    .dropableNoLayout,
    .noLayout {
      display: flex;
      flex-direction: row;
      justify-content: space-around;
      align-items: flex-end;
      padding: 2px;
      min-width: 0;
    }

    .dropableNoLayout.over {
      outline: 3px dashed rgba($gray1, 0.6);
    }

    .layout {
      display: flex;
      flex-direction: column;
      padding: 2px;
      border-radius: 5px;
      border: 1px solid rgba($gray1, 0.4);
      flex: 1 1 20px;
      justify-content: space-around;
      align-items: stretch;
      min-width: 0;

      &.over {
        outline: 3px dashed rgba($gray1, 0.6);
      }

      .layoutTitle {
        text-align: center;
        height: 16px;
        min-height: 16px;
        flex: 0 1 5px;
        display: flex;
        flex-direction: row;

        .titleWrapper {
          flex: 1 1 auto;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
          font-size: 10px;
          line-height: 14px;
        }
      }

      .layoutContent {
        display: flex;
        flex-direction: row;
        flex: 4 4 20px;
        justify-content: space-around;
        align-items: stretch;
      }
    }

    .cell {
      border: 1px solid rgba($gray1, 0.4);
      background: rgba($gray1, 0.15);
      display: flex;
      align-items: center;
      justify-content: center;
      overflow: hidden;
      width: 20px;
      height: 30px;
      font-size: 10px;
      border-radius: 3px;
      margin: 2px;

      &.dummy {
        background-color: transparent;
        background-image: url(../../../assets/sim-small.svg);
        border-radius: 0;
        border: none;
        color: $blue3;
        position: relative;
      }

      &.over {
        outline: 3px dashed rgba($gray1, 0.6);
      }

      &.selected {
        outline: 2px dashed rgba($blue3, 0.6);
      }

      &:global(.selection-target-selected) {
        outline: 2px dashed rgba($blue3, 0.6);
      }
    }

    .priorityContainer {
      transform: translate(-50px, 0);
      display: flex;
      align-items: center;
      width: 0;
      overflow: visible;
      pointer-events: none;

      .priorityTag {
        width: 28px;
        min-width: 28px;
        height: 28px;
        text-align: center;
        line-height: 28px;
        font-weight: bold;
      }
    }
  }
}
