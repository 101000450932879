.wrapper {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-left: 10px;
  white-space: nowrap;
}

.spacer {
  width: 20px;
}

.itemsPerPageLabel {
  align-self: center;
  margin-right: 5px;
  color: rgba(92, 112, 128, 0.6);
}

.total {
  align-self: center;
  margin-right: 10px;
}
