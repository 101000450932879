.selectAll {
  pointer-events: all;
  width: 20px;
  height: 20px;
  min-width: 20px !important;
  min-height: 20px !important;
  line-height: 20px;
  margin: 0;

  /* unset bp3-table-reorder-handle-target styles */
  display: inline-flex;
  cursor: pointer !important;
  position: static;
  top: auto;
  bottom: auto;
  left: auto;
  color: inherit;
}
