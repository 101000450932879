.loginCard {
  display: flex;
  max-width: 450px;
  min-height: 350px;
  margin: 200px auto;
}

.loginForm {
  flex: 1 1 auto;
  min-width: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.eyeButton :global(.bp3-icon) {
  /* ie11 */
  margin-top: 7px !important;
  margin-bottom: 7px !important;
}

.buttonGroup {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.button :global(.bp3-button-spinner) {
  /* ie11 */
  top: 50%;
  left: 50%;
  margin-left: -10px !important;
  margin-top: -10px !important;
  height: 20px !important;
  width: 20px !important;
}

.header {
  font-weight: normal !important;
}
