@import "../../../styles/colors.scss";

.navBar {
  box-shadow: 0 0 0 1px rgba(16, 22, 26, 0.1), 0 1px 1px rgba(16, 22, 26, 0.2), 0 2px 6px rgba(16, 22, 26, 0.2) !important;
}

.titleLeft {
  font-weight: bolder;
  font-size: 24px;
}

.titleRight {
  font-weight: bolder;
  color: $green3;
  font-size: 24px;
}

.content {
  margin: 0 auto;
  width: 1024px;
}

.button,
.userButton {
  margin: 2px;

  &:hover,
  &:global(.bp3-active) {
    color: $white !important;
    background-color: $green3 !important;
  }

  &:focus {
    outline: 0;
  }
}

.userButton :global(.bp3-button-text) {
  max-width: 140px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
