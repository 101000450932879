@import "../../../styles/colors.scss";

.dialogBody {
  display: flex;
  flex-direction: column;
}

.detailsRow {
  display: flex;
  flex-direction: row;
  padding: 3px 0;
  margin: 0;

  :global(.bp3-label) {
    color: $gray1;
    flex: 1;
    text-align: right;
    font-weight: 500;
    margin-right: 10px;
  }

  :global(.bp3-form-content) {
    flex: 2;
  }
}

.options {
  margin-top: 10px;
}

.switchRow {
  display: flex;
  flex-direction: row;
  padding: 0;
  margin: 0;
  line-height: 1;
  width: 435px;

  :global(.bp3-label) {
    color: $gray1;
    flex: 2;
    text-align: right;
    font-weight: 500;
    margin-right: 10px;
    position: relative;

    .switchIcon {
      position: absolute;
      left: 20px;
      top: 7px;
    }

    .switchSymbol {
      position: absolute;
      font-size: large;
      left: 23px;
    }

    .switchIconModifier {
      position: absolute;
      left: 20px;
      top: 7px;
    }
  }

  :global(.bp3-form-content) {
    flex: 1;
  }
}

.invalidFlags {
  color: $red3;
}

.tooltip {
  max-width: 100px;
}
