@import "../../../styles/colors.scss";

.detailsRow {
  display: flex;
  flex-direction: row;
  padding: 3px 0;
  align-items: center;
}

.detailsLabel {
  color: $gray1;
  flex: 1;
  text-align: right;
  font-weight: 500;
  margin-right: 10px;
}

.detailsValue {
  flex: 2;
}

.spacer {
  flex: 1 1 auto;
}
