@import "./colors.scss";
@import "./svg-icon-overrides.scss";
@import "./react-selection.scss";
@import "~@blueprintjs/core/src/blueprint.scss";
@import "~@blueprintjs/datetime/src/blueprint-datetime.scss";
@import "~@blueprintjs/select/src/blueprint-select.scss";
@import "~@blueprintjs/table/src/table.scss";

body {
  margin: 0;
  font-family:
    -apple-system,
    BlinkMacSystemFont,
    "Segoe UI",
    "Roboto",
    "Oxygen",
    "Ubuntu",
    "Cantarell",
    "Fira Sans",
    "Droid Sans",
    "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family:
    source-code-pro,
    Menlo,
    Monaco,
    Consolas,
    "Courier New",
    monospace;
}

html {
  margin-left: calc(100vw - 100%);
  margin-right: 0;
}

.lightbox-modifiers-box {
  top: 5px;
}

.lightbox-btn-close {
  top: 5px;
  left: 5px !important;
}

.lightbox-image-container {
  margin-top: 50px !important;
  height: calc(100% - 153px) !important;
}

/* ie11 wobling fix  */
.bp3-spinner-iefix {
  max-height: 100px;
}

/* ie11 3d button fix  */
button > span {
  -ms-transform: translate(0, 0);
}

/* ie11 textarea shadow fix  */
textarea.bp3-input {
  font-family: inherit;
  font-size: inherit;
  border: 1px solid transparent !important;
  margin: -1px;

  &:focus {
    border-color: $blue3 !important;
    box-shadow: 0 0 0 0 $blue3, 0 0 0 3px rgba($blue3, 0.3), inset 0 1px 1px rgba($black, 0.2);

    &.bp3-intent-danger {
      border-color: $red3 !important;
      box-shadow: 0 0 0 0 $red3, 0 0 0 3px rgba($red3, 0.3), inset 0 1px 1px rgba($black, 0.2);
    }

    &.bp3-intent-success {
      border-color: $green3 !important;
      box-shadow: 0 0 0 0 $green3, 0 0 0 3px rgba($green3, 0.3), inset 0 1px 1px rgba($black, 0.2);
    }

    &.bp3-intent-warning {
      border-color: $orange3 !important;
      box-shadow: 0 0 0 0 $orange3, 0 0 0 3px rgba($orange3, 0.3), inset 0 1px 1px rgba($black, 0.2);
    }
  }
}
