.addCard {
  margin-bottom: 10px;

  .selectedLabel {
    margin-top: 10px !important;
    margin-bottom: 0 !important;
  }

  .selected {
    margin-bottom: 10px;

    :global(.bp3-tag-input-values) {
      max-height: 250px;
      overflow: auto;
    }
  }

  .button {
    margin-right: 10px;
  }

  .fileInput {
    display: none;
  }
}
