@import "../../../styles/colors.scss";

.card {
  margin-left: auto;
  margin-right: auto;
  width: 1024px;
}

.detailsRow {
  display: flex;
  flex-direction: row;
  padding: 3px 0;
  margin: 0;

  :global(.bp3-label) {
    color: $gray1;
    flex: 1;
    text-align: right;
    font-weight: 500;
    margin-right: 10px;
  }

  :global(.bp3-form-content) {
    flex: 2;
  }

  :global(.bp3-popover-wrapper .bp3-button) {
    width: 650px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  :global(.bp3-popover-wrapper .bp3-button-text) {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    max-width: 607px;
  }
}

.buttonRow {
  display: flex;
  flex-direction: row;
  margin-top: 10px;
  justify-content: flex-end;

  :global(.bp3-button) {
    margin-left: 10px;
  }
}
