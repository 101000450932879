@import "../../../../styles/colors.scss";

.layoutCard {
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.layoutWrapper {
  position: relative;
  flex: 1 1 auto;
  border: 1px solid rgba($gray1, 0.4);
  overflow: auto;
  display: flex;
  flex-direction: row;
  padding: 10px;
  max-height: calc(100vh - 358px);
  max-width: calc(100vw - 684px);
  min-width: 535px;

  .selectionArea {
    z-index: 100;
    position: absolute;
    outline: 2px dashed rgba($blue3, 0.4);
    background-color: rgba($blue3, 0.05);

    &.over {
      outline: 3px dashed rgba($blue3, 0.6);
      background-color: rgba($blue3, 0.15);
    }
  }
}

.buttonRow {
  display: flex;
  flex-direction: row;
  margin-top: 10px;
  justify-content: flex-end;

  :global(.bp3-button) {
    margin-left: 10px;
  }
}
