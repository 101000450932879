@import "../../../../styles/colors.scss";

.template {
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
}

.empty {
  flex: 1 1 auto;
  border: 3px dashed mix($gray1, #fff, $weight: 15%);
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-size: 20px;
  color: mix($gray1, #fff, $weight: 30%);

  &.over {
    background-color: rgba($green3, 0.05);
  }
}

.container {
  flex: 1 1 auto;
  border: 1px solid mix($gray1, #fff, $weight: 15%);
  padding: 10px;
  display: flex;
  max-height: calc(100vh - 358px);
  max-width: calc(100vw - 584px);
  min-width: 635px;
  overflow: auto;

  .dropzone {
    margin: 0 5px;
    width: 60px;
    min-width: 60px;
    height: 100%;
    border-radius: 3px;
    flex: 0 0 25px;

    &.over {
      background-color: rgba($green3, 0.05);
    }

    &:last-child {
      flex: 1 1 25px;
    }
  }
}

.equipmentWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 0 0 auto;

  .priority {
    font-weight: 500;
    margin-bottom: 10px;
  }
}

.equipment {
  background: mix($gray1, #fff, $weight: 15%);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-radius: 5px;
  padding: 2px;
  min-width: 120px;

  &.dragging {
    opacity: 0.3;
  }

  .equipmentTitle {
    font-weight: 500;
    text-align: center;
    display: flex;
    align-items: center;
    padding: 2px;

    .dragHandle {
      cursor: move; /* fallback */
      cursor: grab;
      border: 1px solid mix($gray1, #fff, $weight: 30%);
      border-radius: 3px;
      width: 24px;
      min-width: 24px;
      height: 24px;
      display: inline-flex;
      justify-content: center;
      align-items: center;
    }

    .titleWrapper {
      flex: 1 1 auto;
      padding: 0 5px;
      max-width: calc(100% - 50px);

      :global(.bp3-editable-text-content) {
        min-width: 0 !important;
      }
    }

    :global(.bp3-button .bp3-icon) {
      /* ie11 */
      margin-top: 4px !important;
      margin-bottom: 4px !important;
    }
  }

  .equipmentBody {
    cursor: move; /* fallback */
    cursor: grab;
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
  }

  .shelf {
    background: lighten($color: $orange3, $amount: 40%);
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: flex-end;
    border-radius: 5px;
    margin: 2px;
    padding: 2px;
    flex: 1 1 60px;
    position: relative;
    height: 60px;
    min-height: 60px;

    .cell {
      background: lighten($color: $green3, $amount: 45%);
      display: flex;
      align-items: center;
      justify-content: center;
      overflow: hidden;
      width: 20px;
      height: 30px;
      font-size: 10px;
      border-radius: 3px;
      margin: 2px;
    }

    .priorityContainer {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      transform: translate(-60px, 0);
      width: 50px;
      display: flex;
      align-items: center;
      justify-content: center;
      pointer-events: none;

      .priorityTag {
        width: 28px;
        height: 28px;
        text-align: center;
        line-height: 28px;
        font-weight: bold;
      }
    }
  }
}

.buttonRow {
  display: flex;
  flex-direction: row;
  margin-top: 10px;
  justify-content: flex-end;

  :global(.bp3-button) {
    margin-left: 10px;
  }
}
