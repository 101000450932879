.filtersGroup {
  margin-bottom: 10px;
  margin-right: 10px;
  width: 210px;
  float: left;
}

.filtersLabel {
  color: rgba(92, 112, 128, 0.6);
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

/* Daterange Filter */
.filtersDates :global(.bp3-input-group) {
  width: 105px;
}

.filtersNums {
  display: flex;
}

.filtersNumsFrom :global(.bp3-input-group) {
  margin-right: -1px !important;
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

.filtersNumsTo :global(.bp3-input-group) {
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}

/* Daterange Filter */
.filtersDates :global(.bp3-datepicker-caption select + .bp3-icon) {
  right: 2px !important;
}

/* Combo Filter */
.filtersGroup :global(.bp3-popover-wrapper > .bp3-popover-target > div > .bp3-button) {
  width: 210px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.filtersGroup :global(.bp3-popover-wrapper  > .bp3-popover-target > div > .bp3-button > .bp3-button-text) {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 167px;
}
