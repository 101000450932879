@import "../../styles/colors.scss";

.content {
  margin-top: 71px;
  min-height: calc(100vh - 140px);
}

.footer {
  color: rgba($gray1, 0.4);
  border-top: 1px solid rgba($gray1, 0.2);
  margin-left: -20px;
  margin-top: 20px;
  padding: 15px 20px;
  text-align: right;
}
