/* ActionBar */
.actionBar {
  display: flex;
  flex-direction: row;
  margin-top: 10px;
  margin-bottom: 10px;
  min-width: 340px;
}

.button {
  margin-right: 10px;
}

.spacer {
  flex: 1 1 auto;
}
