.clearFiltersButton {
  margin-top: 18px;
  float: right;
  width: 103px;
}

.switchFilter {
  width: 210px;
}

.clearfix {
  clear: both;
}

.filtersGroup :global(.bp3-collapse-body) {
  margin-bottom: 10px;
}
