.container {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
}

.actionBar {
  display: flex;
  flex-direction: row;
  margin-top: 10px;
  margin-bottom: 10px;
  min-width: 340px;

  .button {
    margin-right: 10px;
  }
}

.spacer {
  flex: 1 1 auto;
}

.wsCell {
  padding-top: 7px;
  padding-bottom: 7px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.buttonCell {
  display: flex;
  padding-top: 5px;
  padding-bottom: 5px;
  min-width: 440px;

  .buttonCellButton {
    margin-right: 5px;
  }

  .togleActiveBullon {
    margin-right: 5px;
    width: 146px;
  }
}

.table :global(.bp3-table-row-name) {
  padding: 7px 5px !important;
}

.table :global(.bp3-table-header.bp3-table-last-in-row > .bp3-table-resize-handle-target) {
  display: none;
}

.selectAll {
  pointer-events: all;
  width: 20px;
  height: 20px;
  min-width: 20px !important;
  min-height: 20px !important;
  line-height: 20px;
  margin: 0;

  /* unset bp3-table-reorder-handle-target styles */
  display: inline-flex;
  cursor: pointer !important;
  position: static;
  top: auto;
  bottom: auto;
  left: auto;
  color: inherit;
}
