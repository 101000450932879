@import "../../../../styles/colors.scss";

.equipment {
  background: mix($gray1, #fff, $weight: 15%);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-radius: 3px;
  padding: 1px;
  opacity: 0.7;
  pointer-events: none;

  .equipmentTitle {
    height: 10px;
    min-height: 10px;
    line-height: 10px;
    font-size: 8px;
    font-weight: 500;
    text-align: center;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  .shelf {
    background: lighten($color: $orange3, $amount: 40%);
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: flex-end;
    border-radius: 3px;
    margin: 1px;
    padding: 1px;
    flex: 1 1 20px;
    height: 20px;
    min-height: 20px;

    .cell {
      background: lighten($color: $green3, $amount: 45%);
      display: flex;
      align-items: center;
      justify-content: center;
      overflow: hidden;
      width: 9px;
      height: 9px;
      font-size: 4px;
      border-radius: 2px;
      margin: 1px;
    }
  }
}
