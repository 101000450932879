@import "../../../../styles/colors.scss";

.equipmentCard {
  width: 342px;
  margin-left: 10px;
  display: flex;
  flex-direction: column;

  .searchWrapper {
    display: flex;
    flex-direction: row;
    margin-bottom: 10px;
  }

  .equpmentList {
    margin-top: 10px;
    border: 1px solid rgba($gray1, 0.4);
    flex: 1 1 auto;
    overflow: auto;

    .equpmentItem {
      height: 38px;
      border: none;
      padding: 10px;
      cursor: move; /* fallback */
      cursor: grab;

      .itemWrapper {
        user-select: none;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;

        .icon {
          margin-right: 10px;
        }
      }

      &:hover {
        background: rgba($blue3, 0.1);
      }

      &.dragging {
        opacity: 0.5;

        /* ie fix */
        margin-bottom: 38px;
        padding: 0;
        height: 0;

        .itemWrapper {
          background: rgba($blue3, 0.4);
          padding: 10px;
        }
      }
    }

    .notFound {
      padding: 10px;
      color: rgba($gray1, 0.4);
    }

    :global(.bp3-spinner) {
      margin-top: 20px;
    }
  }
}
